@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.css-19r6kue-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-rorn0c-MuiTableContainer-root {
  border-radius: 5px !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: none !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

* {
  font-family: 'Poppins', sans-serif !important;
}