.loginContainer {
  /* border: 2px solid green; */
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  overflow-y: hidden;
}

.loginLeft {
  flex: 1;
  /* border: 2px solid red; */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 0rem 7rem;
}

.loginRight {
  /* border: 2px solid black; */
  width: 50%;
  height: 100%;
}

.loginImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .loginLeft {
    padding: 0rem 3rem;
  }
}

@media screen and (max-width: 900px) {
  .loginRight {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .loginLeft {
    padding: 0rem 1.5rem;
  }
}
